.RedNumber {
  .Result {
    margin: 5px;
    border: 1px solid #ddd;
    white-space: pre;
    height: calc(100vh - 55px);
    overflow-y: auto;
    padding: 5px
  }
  @media print {
    .Result {
      white-space: pre;
    }
  }
}