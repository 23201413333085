.BrowseTicket {
  .FilterBox {
    display: flex;
    flex-direction: column;
    padding: 10px;
    
    input {
      border: 1px solid;
      height: 38px;
      width: 100%;
    }
    .FindBtn {
      margin-top: 10px;
      background: #dae0e5;
      font-weight: 700
    }
  }

  .Result {
    margin: 5px;
    border: 1px solid #ddd;
    white-space: pre;
    height: calc(100vh - 55px);
    overflow-y: auto;
    padding: 5px
  }
  @media print {
    .Result {
      white-space: pre;
    }
  }
}