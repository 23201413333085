.Navbar {
  padding: 5px;
  .LeftItems {
    width: 30%;
    button {
      padding: 5px 2px;
    }
  }
  .Title {
    width: 40%;
    text-align: center
  }
  .RightItems {
    width: 30%;
    button {
      padding: 5px 2px;
      margin-left: auto;
    }
  }
}
@media print {
  .Navbar {
    display: none; 
  }
}