.BrowseTicket {
  .FilterBox {
    display: flex;
    flex-direction: column;
    padding: 10px;
    .Row {
      display: flex;
      label {
        line-height: 38px;
      }
      select {
        width: fit-content;
        margin: 0 5px;
      }
      .FindBtn {
        height: 38px;
      }
      input {
        margin: 0 5px;
        border: 1px solid;
        height: 38px;
        padding-left: 5px;
      }
    }
  }
  .Content {
    height: calc(100vh - 200px);
    margin: 5px;
    border: 1px solid #ddd;
    overflow-y: auto;
    div {
      white-space: pre;
      margin: 10px;
      border: 1px solid #e6e6e3;
      border-radius: 7px;
      padding: 12px;
      background: aliceblue;
    }
  }
  .Footer {
    .CtaWrapper {
      display: flex;
      justify-content: space-around;
      margin: 2px -5px 0 -5px;
      background: linear-gradient(
        to right,
        rgb(116, 116, 191),
        rgb(52, 138, 199)
      );
      .CtaButton {
        background: transparent;
        border: none;
        color: white;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 7px;
      }
    }
  }
}