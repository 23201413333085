.Content {
  padding: 5px;
  display: flex;
  flex-direction: column;
  select {
    margin-bottom: 5px;
  }
  button {
    width: 100%;
    margin-bottom: 5px;
    background: #e4e5e6a3;
  }
}