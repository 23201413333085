.Alert {
  // margin-top: calc((100vh - 158px) / 2);
  div[role=document] {
    box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.1);
    position: relative;
    width: auto;
    margin: 0 1.5rem;
    pointer-events: none;
    text-align: center;
  }
  .Content {
    background: #f5e3dc;
    .Header {
      border-bottom: 0;
      display: unset;
      padding: 10px 5px;
      h5 {
        text-align: center;
      }
    }
    .Body {
      padding: 5px;
      font-weight: 700;
      white-space: pre;
      max-height: 270px;
      overflow-y: auto;
      text-align: center;
    }
    .Footer {
      // width: 100%;
      border-top: 0;
      // display: unset;
      padding: 5px 10px;
      justify-content: center;
      
      button {
        width: 25%;
        box-shadow: 1px 1px 12px 0px rgba(0, 0, 0, 0.1);
        background: #dad8d5;
        padding: 5px 4px;
      }
    }
  }
}