.AddTicket {
  nav {
    padding: 5px;
  }
  .TicketContent {
    padding: 0 5px;
    // display: flex;
    // flex-direction: column;
    overflow-y: auto;
    .D{
      height: 28px;
    }
    button {
      height: 38px;
      border-radius: 0;
      text-align: left;
    }
  }
  .ListUserWrap {
    width: -webkit-fill-available;
    font-size: 12px !important;

  }
  .ListUser {
    width: 35%;
    float: left;
    border-right: 1px solid gray;
    padding-right: 20px;
    margin-right: 10px;
    select {
      border: none;
      font-size: 14px !important;
    }
  }
  .ListUserMobile {
    font-weight: bold;
    line-height: 38px;
    margin: 0 10px;
    float: left;
  }
  .Footer {
    z-index: 2;
    padding: 0 5px;
    background: white;
    .OrderFormat {
      button {
        border: 1px solid #ddd;
        width: 50px;
      }
      .SelectOrder {
        width: -webkit-fill-available;
        margin: 0px 2px;
      }
    }
    .CtaWrapper {
      display: flex;
      justify-content: space-between;
      margin: 2px -5px 0 -5px;
      background: linear-gradient(
        to right,
        rgb(116, 116, 191),
        rgb(52, 138, 199)
      );
      .CtaButton {
        // width: -webkit-fill-available;
        background: transparent;
        border: none;
        color: white;
        font-weight: 700;
        font-size: 14px;
        padding: 10px 7px;
      }
    }
    .NumberKeyboard {
      margin: 2px -5px;
      div {
        padding: 0px;
        img {
          width: 100%;
          height: 50px;
        }
      }
    }
    .SpecialKeyboard {
      margin: 0 -5px 5px -5px;
      justify-content: space-between;
      div {
        padding: 0px;
        img {
          width: 100%;
          height: 50px;

        }
      }
    }
    // .Three {
    //   margin-top: 2px;
    //   button {
    //     width: -webkit-fill-available;
    //     background-color: transparent;
    //     border: 1px solid #ddd;
    //     margin-left: 2px;
    //     border-radius: 0;
    //   }
    // }
  }
}
