.SignIn {
  background-image: url("../../images/bg.jpg");
  height: 100%;
  .Form {
    display: block;
    position: absolute;
    clear: left;
    width: 350px;
    height: 400px;
    max-width: 350px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 15px 20px 45px;
    margin: 20px auto;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: #fff;
    border-radius: 20px;
    background-image: url("../../images/bg_login.jpg");
    background-size: 100% 100%;
    top: 50%;
    left: 50%;
    margin-left: -175px;
    margin-top: -175px;
    .LoginTitle {
      display: block;
      width: 100%;
      margin-bottom: 40px;
      color: #fff;
      img {
        display: block;
        clear: left;
        margin: 10px auto;
      }
      span {
        display: block;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 18px;
      }
    }
    input {
      background: rgba(157, 205, 248, 0.62);
      display: block;
      clear: left;
      width: 100%;
      border: 1px solid #fff;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 10px 5px;
      border-radius: 5px;
      vertical-align: middle;
      transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
      transition-property: border-color, box-shadow;
      transition-duration: 0.15s, 0.15s;
      transition-timing-function: ease-in-out, ease-in-out;
      transition-delay: 0s, 0s;
      margin: 10px 0px;
      color: #fff;
    }
    .Pin {
      max-width: 40%;
    }
    .Msg {
      color: red;
      display: block;
    }
    .BtnLogin {
      display: block;
      clear: left;
      width: 100%;
      background-color: #428bca;
      border-color: #357ebd;
      color: #fff;
      padding: 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 6px;
      text-align: center;
      font-weight: 700;
      text-decoration: none;
      margin-top: 20px;
      &:hover {
        background-color: #50a3ca;
        text-decoration: none;
        color: #fff;
      }
    }
  }
}
